import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import {
  AlertInfo,
  OperationType,
  OrderManagementServiceInstance,
  ProductionOrderDetails,
  ProductWithValue,
} from '../../services/OrderManagementService'
import Alerts from '../generic/Alerts'
import { GetDeliveryOptionsRequest, GetPickupPointsRequest, PickupPoint } from '../../api'
import {
  ProductionOrderDeliveryAddress,
  RedoDeliveryAddress,
  RedoPickupPointData,
  RedoProductionOrdersRequest,
} from '../../api/productionOrder'
import * as actions from '../../actions/redoActions'
import { AppStore } from '../../reducers/states'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../forms/Select'
import OrderStructure from '../forms/OrderStructure'
import { DeliveryOptionsComponent } from './DeliveryOptions'
import { RedoSummary } from './RedoSummary'
import LazyContainer from '../generic/LazyContainer'
import * as DeliveryOptionsService from '../../services/DeliveryOptionsService'

interface OrderRedoFormWithDeliveryOptionsProps {
  toggleSidePanel(): void
}

interface OrderRedoFormWithDeliveryOptionsState {
  appVersion: string
  error?: string
  deliveryError?: string
  warning?: string
  comment: string
  products: ProductWithValue[]
  productionOrders: ProductionOrderDetails[]
  reasonCode?: string
  reasonPhrase?: string
  step: number
  isLoading: boolean
  shippingAddress?: ProductionOrderDeliveryAddress
  deliveryAddress?: RedoDeliveryAddress
  pickupPointData?: RedoPickupPointData
  deliveryMethod: string
  deliveryType: string
  originalPickupPoint?: PickupPoint
  originalDeliveryMethod: string
  originalDeliveryType: string
  selectOriginalDeliveryOption: boolean
}

// Values should always match the ones configured in:
// OnlineShop -> PickupPointsSectionContainer.
// If they do not match, customers and care agents will
// see a different set of pickup points for an order.
const maxCollectionPoints = 5
const maxDistanceInKm = 10

export const OrderRedoFormWithDeliveryOptions = ({ toggleSidePanel }: OrderRedoFormWithDeliveryOptionsProps) => {
  const data = useSelector((state: AppStore) => state.orderDetails.data)
  const panelData = useSelector((state: AppStore) => state.redoPanel)
  const configuration = useSelector((state: AppStore) => state.global.configuration)
  const reasons = configuration.resetReasons
  const originalDeliveryDetails =
    data?.deliveryInfo?.find((x) => x.productionOrderId === data?.preselectedProductionOrderId) ?? data?.deliveryInfo?.[0]

  const dispatch = useDispatch()

  const [orderRedoFormState, setOrderRedoFormState] = useState<OrderRedoFormWithDeliveryOptionsState>({
    appVersion: process.env.REACT_APP_VERSION || '',
    error: '',
    deliveryError: '',
    warning: undefined,
    comment: '',
    products: [],
    productionOrders: [],
    step: 1,
    isLoading: false,
    shippingAddress: {
      customerTitle: data?.shippingAddress?.customerTitle,
      customerFirstName: data?.shippingAddress?.customerFirstName,
      customerPrefix: data?.shippingAddress?.customerPrefix,
      customerLastName: data?.shippingAddress?.customerLastName,
      companyName: data?.shippingAddress?.companyName,
      addressLine1: data?.shippingAddress?.addressLine1,
      addressLine2: data?.shippingAddress?.addressLine2,
      zipCode: data?.shippingAddress?.zipCode,
      city: data?.shippingAddress?.city,
      countryId: data?.shippingAddress?.country,
      customerPhone: data?.shippingAddress?.customerPhone,
      customerEmail: data?.shippingAddress?.customerEmail,
    } as ProductionOrderDeliveryAddress,
    deliveryAddress: undefined,
    pickupPointData: undefined,
    deliveryMethod: '',
    deliveryType: '',
    originalPickupPoint: undefined,
    originalDeliveryType: originalDeliveryDetails?.deliveryType!,
    originalDeliveryMethod: originalDeliveryDetails?.deliveryMethod!,
    selectOriginalDeliveryOption: false,
  })

  useEffect(() => {
    //Get PUDO delivery options
    const pudoDeliveryOption = panelData.deliveryOptions?.shipments?.[0].deliveryOptions.find(
      (x) => x.shippingDetails.deliveryMethod === 'PUDO'
    )
    // There is at least one pickup point option available and but pickupPoint details not received yet.
    if (pudoDeliveryOption !== undefined && panelData.pickupPoints?.pickupPoints === undefined) {
      dispatch(
        actions.requestPickupPoints({
          countryCode: data?.shippingAddress?.country,
          maxCollectionPoints: maxCollectionPoints,
          maxDistanceInKm: maxDistanceInKm,
          networkId: pudoDeliveryOption?.shippingDetails.carrierNetworkId,
          postalCode: data?.shippingAddress?.zipCode,
        } as GetPickupPointsRequest)
      )
    } else {
      // Set original pickupPoint or null
      setOrderRedoFormState((prevState) => ({
        ...prevState,
        originalPickupPoint: panelData.pickupPoints?.pickupPoints?.find(
          (x) => x.address.postalCode === orderRedoFormState.shippingAddress?.zipCode
        ),
      }))
    }

    //Set automatically delivery options if needed and not defined
    if (
      orderRedoFormState.selectOriginalDeliveryOption &&
      orderRedoFormState.deliveryAddress === undefined &&
      panelData.deliveryOptions?.shipments
    ) {
      if (isOriginalDeliveryOptionAvailable()) {
        if (orderRedoFormState.originalDeliveryMethod === 'PUDO') {
          if (orderRedoFormState.originalPickupPoint !== undefined) {
            handlePickupPointSelection(orderRedoFormState.originalPickupPoint!)
          } else {
            // Choose another pickup point
            setOrderRedoFormState((prevState) => ({
              ...prevState,
              step: 2,
              warning: 'Original pickup point is no longer available, please select a new delivery option below',
            }))
          }
        } else {
          const address: RedoDeliveryAddress = {
            customerTitle: data?.shippingAddress?.customerTitle!,
            customerFirstName: data?.shippingAddress?.customerFirstName!,
            customerPrefix: data?.shippingAddress?.customerPrefix!,
            customerLastName: data?.shippingAddress?.customerLastName!,
            addressLine1: data?.shippingAddress?.addressLine1!,
            addressLine2: data?.shippingAddress?.addressLine2,
            zipCode: data?.shippingAddress?.zipCode!,
            city: data?.shippingAddress?.city!,
            countryId: data?.shippingAddress?.country!,
            companyName: data?.shippingAddress?.companyName,
            customerPhone: data?.shippingAddress?.customerPhone,
            customerEmail: data?.shippingAddress?.customerEmail,
          }

          handleDeliveryAddressSelection(address, orderRedoFormState.originalDeliveryType)
        }
      } else {
        // Choose delivery options
        setOrderRedoFormState((prevState) => ({
          ...prevState,
          step: 2,
          warning: 'Original delivery option is no longer available, please select a new delivery option below',
        }))
      }
    }

    // On error received
    if (panelData.error !== '') {
      setOrderRedoFormState((prevState) => ({
        ...prevState,
        step: 1,
        deliveryError: panelData.error,
      }))
    }
  }, [panelData.deliveryOptions, panelData.pickupPoints, orderRedoFormState.originalPickupPoint, panelData.error])

  const isOriginalDeliveryOptionAvailable = (): boolean => {
    const isOriginalDeliveryOptionAvailable =
      panelData.deliveryOptions?.shipments[0].deliveryOptions.find(
        (x) =>
          x.shippingDetails.deliveryMethod == orderRedoFormState.originalDeliveryMethod &&
          x.shippingDetails.deliveryType === orderRedoFormState.originalDeliveryType
      ) !== undefined

    return isOriginalDeliveryOptionAvailable
  }

  const handleProductsChange = (products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>): void => {
    const error = _(products)
      .filter((x) => !!x.maxValue && !!x.tooltip && x.value > x.maxValue)
      .map((x) => x.tooltip!)
      .uniq()
      .join(' ')

    setOrderRedoFormState((prevState) => ({ ...prevState, error, products, productionOrders }))
  }

  const handleCommentChange = (event: any): void => {
    const value = event.target.value

    setOrderRedoFormState((prevState) => ({ ...prevState, error: '', comment: value }))
  }

  const handleReasonChange = (event: any): void => {
    const code = event.target.value
    const [match] = reasons.filter((r) => r.errorID === code)

    setOrderRedoFormState((prevState) => ({ ...prevState, error: '', reasonCode: code, reasonPhrase: match.description }))
  }

  const validateFormData = (): boolean => {
    const invalidProductsDuringSubmit =
      orderRedoFormState.products.length === 0 || orderRedoFormState.products.every((p) => p.value === 0)
    const invalidProductionOrdersDuringSubmit =
      orderRedoFormState.productionOrders.length === 0 ||
      orderRedoFormState.productionOrders.every((po) => po.products.every((p) => p.value === 0))

    if (invalidProductsDuringSubmit && invalidProductionOrdersDuringSubmit) {
      setOrderRedoFormState((prevState) => ({ ...prevState, error: 'Check entire order or some part' }))
      return false
    }

    if (!orderRedoFormState.comment) {
      setOrderRedoFormState((prevState) => ({ ...prevState, error: 'Please put short reason' }))
      return false
    }

    if (orderRedoFormState.comment.trim().length > 254) {
      setOrderRedoFormState((prevState) => ({
        ...prevState,
        error: 'Please input a shorter reason. (Maximum 254 characters)',
      }))
      return false
    }

    if (!orderRedoFormState.reasonCode) {
      setOrderRedoFormState((prevState) => ({ ...prevState, error: 'Please select a reason code' }))
      return false
    }

    return true
  }

  const handleBack = (step: number): void => {
    if (orderRedoFormState.selectOriginalDeliveryOption) {
      step = 1
    }
    setOrderRedoFormState((prevState) => ({
      ...prevState,
      step: step,
      deliveryAddress: undefined,
      pickupPointData: undefined,
      deliveryMethod: '',
      deliveryType: '',
      selectOriginalDeliveryOption: false,
      error: '',
      warning: undefined,
    }))
  }

  const handleGetDeliveryOptions = (
    step: number,
    selectOriginalDeliveryOption: boolean,
    newAddress?: RedoDeliveryAddress
  ): void => {
    if (!validateFormData()) {
      return
    }

    const deliveryAddress: ProductionOrderDeliveryAddress = {
      addressLine1: newAddress?.addressLine1 ?? orderRedoFormState.shippingAddress?.addressLine1 ?? '',
      addressLine2: newAddress?.addressLine2 ?? orderRedoFormState.shippingAddress?.addressLine2,
      city: newAddress?.city ?? orderRedoFormState.shippingAddress?.city ?? '',
      zipCode: newAddress?.zipCode ?? orderRedoFormState.shippingAddress?.zipCode ?? '',
      countryId: newAddress?.countryId ?? orderRedoFormState.shippingAddress?.countryId ?? '',
      customerTitle: orderRedoFormState.shippingAddress?.customerTitle,
      customerFirstName: orderRedoFormState.shippingAddress?.customerFirstName ?? '',
      customerPrefix: orderRedoFormState.shippingAddress?.customerPrefix,
      customerLastName: orderRedoFormState.shippingAddress?.customerLastName ?? '',
      customerEmail: data?.orderDeliveryAddress?.customerEmail ?? '',
      customerPhone: data?.shippingAddress?.customerPhone ?? '',
      companyName: orderRedoFormState.shippingAddress?.companyName,
    }

    const requestDeliveryOptions: GetDeliveryOptionsRequest = {
      orderCode: data?.id!,
      productionOrders: orderRedoFormState.productionOrders,
      deliveryAddress: deliveryAddress,
    }

    setOrderRedoFormState((prevState) => ({
      ...prevState,
      step: step,
      selectOriginalDeliveryOption: selectOriginalDeliveryOption,
      shippingAddress: deliveryAddress,
      warning: undefined,
      error: '',
    }))

    dispatch(actions.requestDeliveryOptions(requestDeliveryOptions))
  }

  const handleDeliveryAddressSelection = (newAddress: RedoDeliveryAddress, deliveryType: string): void => {
    setOrderRedoFormState((prevState) => ({
      ...prevState,
      shippingAddress: {
        ...prevState.shippingAddress,
        addressLine1: newAddress.addressLine1,
        addressLine2: newAddress.addressLine2,
        zipCode: newAddress.zipCode,
        city: newAddress.city,
        countryId: newAddress.countryId,
      } as ProductionOrderDeliveryAddress,
      deliveryAddress: {
        customerTitle: newAddress.customerTitle,
        customerFirstName: newAddress.customerFirstName,
        customerPrefix: newAddress.customerPrefix,
        customerLastName: newAddress.customerLastName,
        addressLine1: newAddress.addressLine1!,
        addressLine2: newAddress.addressLine2!,
        city: newAddress.city!,
        countryId: newAddress.countryId!,
        zipCode: newAddress.zipCode!,
        companyName: newAddress.companyName,
        customerPhone: newAddress.customerPhone,
        customerEmail: newAddress.customerEmail,
      },
      pickupPointData: undefined,
      deliveryMethod: 'Home',
      deliveryType: deliveryType,
      deliveryOptions: undefined,
      warning: undefined,
    }))

    // Recalculate delivery options
    handleGetDeliveryOptions(3, false, newAddress)
  }

  const handlePickupPointSelection = (pickupPointParam: PickupPoint): void => {
    setOrderRedoFormState((prevState) => ({
      ...prevState,
      deliveryAddress: {
        customerFirstName: data?.shippingAddress?.customerFirstName,
        customerLastName: data?.shippingAddress?.customerLastName,
        addressLine1: pickupPointParam.address?.street,
        addressLine2: pickupPointParam.address?.street2,
        city: pickupPointParam.address?.city,
        countryId: orderRedoFormState.shippingAddress?.countryId!,
        zipCode: pickupPointParam.address?.postalCode,
      },
      pickupPointData: {
        pickupPointExternalIdentifier: pickupPointParam.address?.externalIdentifier,
        pickupPointName: pickupPointParam.address?.name,
      },
      deliveryMethod: 'PUDO',
      deliveryType: 'Standard',
      warning: undefined,
      step: 3,
    }))
  }

  const resolveCustomerMustReceiveBy = (): string => {
    return (
      DeliveryOptionsService.resolveEstimatedDeliveryDate(
        orderRedoFormState.deliveryMethod,
        orderRedoFormState.deliveryType,
        panelData.deliveryOptions
      ) ?? 'Not defined'
    )
  }

  const submitRedoOrder = (): void => {
    const redoPayload: RedoProductionOrdersRequest = {
      comment: orderRedoFormState.comment,
      customerMustReceiveBy: resolveCustomerMustReceiveBy(),
      orderCode: data?.id!,
      productionOrders: orderRedoFormState.productionOrders,
      deliveryAddress: orderRedoFormState.deliveryAddress!,
      pickupPointData: orderRedoFormState.pickupPointData,
      deliveryMethod: orderRedoFormState.deliveryMethod,
      deliveryType: orderRedoFormState.deliveryType,
      reasonPhrase: orderRedoFormState.reasonPhrase!,
      reasonCode: orderRedoFormState.reasonCode!,
    }

    dispatch(actions.submitProductionOrderRedo(redoPayload))
    toggleSidePanel()
  }

  const allProductionOrdersDisabled = orderRedoFormState.productionOrders.every((p) => !!p.disabled)
  const allProductsDisabled = orderRedoFormState.products.every((p) => !!p.disabled)
  const allDisabled = allProductionOrdersDisabled && allProductsDisabled

  const productWarnings = OrderManagementServiceInstance.getWarnings(orderRedoFormState.products)

  const warnings = orderRedoFormState.warning
    ? productWarnings.concat({
        color: 'warning',
        content: orderRedoFormState.warning,
      } as AlertInfo)
    : productWarnings

  const errors = [orderRedoFormState.error]
    .filter((x) => x)
    .map(
      (content) =>
        ({
          color: 'danger',
          content,
        } as AlertInfo)
    )

  const nonBlockingErrors = [orderRedoFormState.deliveryError]
    .filter((x) => x)
    .map(
      (content) =>
        ({
          color: 'danger',
          content,
        } as AlertInfo)
    )

  return (
    <>
      <LazyContainer isLoading={panelData.isLoading}>
        <Alerts items={errors.concat(nonBlockingErrors).concat(warnings)} />
        {orderRedoFormState.step > 1 && (
          <a className="mr-4" onClick={() => handleBack(orderRedoFormState.step - 1)}>
            &lt; back
          </a>
        )}
        {orderRedoFormState.step === 1 && (
          <Form>
            <OrderStructure
              data={data!}
              preselectedProductionOrderId={data?.preselectedProductionOrderId}
              hideFulfillmentProducts={true}
              handleChange={handleProductsChange}
              operation={OperationType.Reset}
              ignoreCancelledProductionOrders={true}
            />
            <FormGroup>
              <Label className={allDisabled ? 'disabled' : undefined}>Redo code</Label>
              <Select
                emptyText="-- select a code --"
                onChange={handleReasonChange}
                value={orderRedoFormState.reasonCode}
                options={reasons.map((x) => ({ value: x.errorID, text: x.description }))}
                disabled={allDisabled}
              />
            </FormGroup>
            <FormGroup>
              <Label for="OrderManagementView_ReasonPhrase" className={allDisabled ? 'disabled' : undefined}>
                Redo reason
              </Label>
              <Input
                type="textarea"
                id="OrderManagementView_ReasonPhrase"
                name="comment"
                value={orderRedoFormState.comment}
                onChange={handleCommentChange}
                disabled={allDisabled}
              />
            </FormGroup>
            {configuration.permissions && configuration.permissions.isSupportEngineer && (
              <button
                type="button"
                style={{ width: '100%', margin: '20px 0px' }}
                disabled={errors.length !== 0 || allDisabled}
                onClick={() => handleGetDeliveryOptions(2, false, undefined)}>
                Choose new delivery options
              </button>
            )}
            {configuration.permissions && !configuration.permissions.isSupportEngineer && (
              <button
                type="button"
                style={{ width: '100%', margin: '20px 0px' }}
                disabled={errors.length !== 0 || allDisabled}
                onClick={() => handleGetDeliveryOptions(1, true, undefined)}>
                Validate Delivery Options
              </button>
            )}
          </Form>
        )}
        {orderRedoFormState.step === 2 && (
          <DeliveryOptionsComponent
            originalPickupPoint={orderRedoFormState.originalPickupPoint}
            shippingAddress={orderRedoFormState.shippingAddress!}
            onAddressSelected={handleDeliveryAddressSelection}
            onPudoSelected={handlePickupPointSelection}
            originalDeliveryType={orderRedoFormState.originalDeliveryType}
            originalDeliveryMethod={orderRedoFormState.originalDeliveryMethod}></DeliveryOptionsComponent>
        )}
        {orderRedoFormState.step === 3 && (
          <RedoSummary
            products={orderRedoFormState.products}
            productionOrders={orderRedoFormState.productionOrders}
            deliveryAddress={orderRedoFormState.deliveryAddress}
            pickUpPointData={orderRedoFormState.pickupPointData}
            deliveryMethod={orderRedoFormState.deliveryMethod}
            deliveryType={orderRedoFormState.deliveryType}
            deliveryDate={resolveCustomerMustReceiveBy()}
            handleSubmit={submitRedoOrder}
            handleCancel={toggleSidePanel}></RedoSummary>
        )}
      </LazyContainer>
    </>
  )
}
