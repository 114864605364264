import { PayloadAction } from '@reduxjs/toolkit'

export interface Note {
  body: string
  timestamp?: Date
  username: string | undefined
  secondaryKey: string | undefined
}

export enum CommentType {
  Unknown = 0,
  OrderNote = 1,
  CustomerNote = 2,
  MixUpNote = 3,
}

export interface NoteCommunicationBase {
  parentId: string
  type: CommentType
}

export interface NoteRequestBase {
  body: string
}

export interface NoteRequest extends NoteRequestBase, NoteCommunicationBase {
  secondaryKey: string | undefined
}

export interface NoteBulkRequestBase {
  identifiers: string,
  message: string
}

export interface NoteBulkRequest {
  type: CommentType,
  identifiers: string[],
  message: string
}

export interface GroupedCommentsSaveResponse {
  identifier: string,
  statusCode: number,
  errorMessage: string
  products: string[]
}

export interface NoteBulkStatusResponse {
  id: string
  totalItems: number
  itemsProcessed: number
  remaining: string
  createdAt: string
  updatedAt: string
  result?: NoteBulkResponse
  valid: boolean
  inprogress: boolean
}

export interface NoteBulkResponse {
  items: GroupedCommentsSaveResponse[]
}

export interface NoteResponse extends NoteCommunicationBase {
  response: Note
}

export interface BulkNoteRequest extends NoteCommunicationBase { }

export interface BulkNoteResponse extends NoteCommunicationBase {
  notes: Note[]
}

export function isCommentRelatedToMixUp(mixUpId: string, action: PayloadAction<NoteCommunicationBase>) {
  if (action.payload.type !== CommentType.MixUpNote) return false
  if (action.payload.parentId !== mixUpId) return false
  return true
}

export function isCommentRelatedToOrder(orderCode: string | undefined, action: PayloadAction<NoteCommunicationBase>) {
  if (action.payload.type !== CommentType.OrderNote) return false
  if (action.payload.parentId !== orderCode) return false
  return true
}
