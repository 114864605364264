import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as bulkOperationsActions from '../actions/bulkOperationsActions'
import * as actions from '../actions/commentActions'
import * as global from '../actions/globalActions'
import { BulkNoteRequest, CommentType, NoteBulkRequest, NoteRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'
import { AxiosError } from 'axios'

function wait(ms: number) {
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}

function* send(action: PayloadAction<NoteRequest>) {
  try {
    const response = yield call(ApiServiceInstance.post, 'comment', action.payload)

    yield put(
      actions.sentComment({
        type: action.payload.type,
        parentId: action.payload.parentId,
        response: response.data,
      })
    )
  } catch (e) {
    const errorMessage = e.response.data.message + ": " + e.response.data.exception
    yield put(Errors.toErrorAction(e, errorMessage))
  }
}

function* sendBulk(action: PayloadAction<NoteBulkRequest>) {
  try {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    yield put(global.showSpinner())
    let response = yield call(ApiServiceInstance.post, 'comment/bulk/start', action.payload)

    var requestId = response.data.id;
    while (response.result == null) {

      response = yield call(ApiServiceInstance.get, 'comment/bulk/status/' + requestId);
      yield put(global.hideSpinner())
      yield put(
        bulkOperationsActions.bulkCommentStatusReceived({
          ...response,
          inprogress: true,
        }));
      if (response.result == null) {
        wait(1000);
      }
    }
    yield put(
      bulkOperationsActions.bulkCommentStatusReceived({
        ...response,
        valid: true,
        inprogress: false,
      }));
  } catch (e) {
    console.error(e);
    let errorMessage = ''
    if (e.response?.data?.exception) {
      errorMessage = e.response.data.message + ": " + e.response.data.exception
    }
    else if (e.response?.data) {
      errorMessage = e.response.data;
    }
    else if (e.response?.exception) {
      errorMessage = e.response.message + ": " + e.response.exception
    }
    else {
      errorMessage = (e as AxiosError).message;
    }
    yield put(Errors.toErrorAction(e, errorMessage))
  } finally {
    yield put(global.hideSpinner())
  }
}

function* requestBulk(action: PayloadAction<BulkNoteRequest>) {
  try {
    // TODO: extend for other entities
    const entity = action.payload.type === CommentType.OrderNote ? 'order' : '[undefined]'

    const response = yield call(ApiServiceInstance.get, `comment/${entity}/${action.payload.parentId}`)

    yield put(
      actions.receivedBulk({
        type: action.payload.type,
        parentId: action.payload.parentId,
        notes: response,
      })
    )
  } catch (e) {
    let errorMessage = 'something went wrong'
    if (e.response) {
      errorMessage = e.response.message + ": " + e.response.exception
    }
    yield put(Errors.toErrorAction(e, errorMessage))
  }
}

export default function* root() {
  yield takeLatest(actions.sendComment, send)
  yield takeLatest(bulkOperationsActions.bulkCommentRequest, sendBulk)
  yield takeLatest(actions.requestBulk, requestBulk)
}
