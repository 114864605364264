import _ from 'lodash'
import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkCommentRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { CommentType, GroupedCommentsSaveResponse, NoteBulkRequestBase, NoteBulkResponse } from '../../api'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import { BulkNoteForm } from '../forms/BulkNotesForm'
import BulkProgress from './BulkProgress'
import ExportCSV from '../generic/ExportCSV'
import moment from 'moment'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  identifiers: string
}

class BulkCommentPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkComment = this.sendBulkComment.bind(this)
  }

  sendBulkComment(x: NoteBulkRequestBase) {
    const identifiersList = x.identifiers
      .replaceAll('\r\n', ',')
      .replaceAll('\n', ',')
      .replaceAll('\r', ',')
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array
    this.props.dispatch(
      bulkCommentRequest({
        type: CommentType.OrderNote,
        message: x.message,
        identifiers: identifiersList,
      })
    )
  }

  renderForm() {
    return <BulkNoteForm form={`test_notes`} onSubmit={this.sendBulkComment}></BulkNoteForm>
  }

  toCsvData(data: NoteBulkResponse) {
    return [
      ["Identifiers", "Products", "Status", "StatusCode", "Message"],
      ...data.items!.map((item: GroupedCommentsSaveResponse) => [item.identifier, item.products.join(" "), item.statusCode === 200 ? 'Success' : 'Error', item.statusCode, item.errorMessage])
    ];
  }

  renderResponse() {
    const groupedResponse = _(this.props.bulkNoteStatusResponse.result?.items).groupBy((x) => Math.floor(x.statusCode / 100) * 100)
      .map((value, key) => ({ statusCode: key, responses: value }))
      .value()
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Operation Result</h1>
            {this.props.bulkNoteStatusResponse.result?.items.some((x) => x.statusCode !== 200)
              ? 'There were some errors updating notes, please check report below'
              : 'All notes were successfully updated'}
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>

        {
          groupedResponse.map((x, index) => (
            <div key={index} className="response-container">
              <Row key={'state_' + index}>
                <Col md="2" className="column-highlight">
                  State:{' '}
                </Col>
                <Col md="10" className={x.statusCode === '200' ? 'status-success' : 'status-error'}>
                  {x.statusCode === '200' ? 'Success' : 'Error'}
                </Col>
              </Row>
              {
                <Row key={'products_headers_' + index}>
                  <Col md="4" className="column-highlight">
                    Identifiers:{' '}
                  </Col>
                  <Col md="4" className="column-highlight">
                    Products:{' '}
                  </Col>
                  <Col md="2" className="column-highlight">
                    Message:{' '}
                  </Col>
                </Row>
              }
              {x.responses.map((response, responseIndex) => (
                <Row key={'products_' + responseIndex}>
                  <Col md="4">{response.identifier}</Col>
                  <Col md="4">{response.products.join(", ")}</Col>
                  {response.errorMessage && <Col md="2">{response.errorMessage}</Col>}
                </Row>
              ))}
            </div>
          ))
        }
        <Row>
          <Col md="12" className="export-csv">
            <ExportCSV data={this.toCsvData(this.props.bulkNoteStatusResponse.result!)} fileName={`bulkcomment-${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`} />
          </Col>
        </Row>
      </Container>
    )
  }

  renderProgress() {
    return (
      <BulkProgress itemsProcessed={this.props.bulkNoteStatusResponse.itemsProcessed} totalItems={this.props.bulkNoteStatusResponse.totalItems} remaining={this.props.bulkNoteStatusResponse.remaining} />
    )
  }

  render() {
    if (this.props.bulkNoteStatusResponse.inprogress) {
      return this.renderProgress();
    }
    return this.props.bulkNoteStatusResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkCommentPanel
