import React from 'react'
import Container from 'reactstrap/lib/Container'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'

interface Props {
  itemsProcessed: number
  totalItems: number
  remaining: string
}

class BulkProgress extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }


  render() {
    return (
      <Container fluid className="p-0" style={{ width: '100%', height: '100%' }}>
        <div style={{ flex: 1 }}>
          <progress className="bulkProgress" value={this.props.itemsProcessed} max={this.props.totalItems} />
          <br />
          {this.props.itemsProcessed}/{this.props.totalItems} processed, {this.props.remaining} Time Remaining
        </div>
      </Container>
    )
  }
}

export default BulkProgress
