import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkCancelRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkCancelBase, BulkCancelResponse, CancelProductionOrderFailed, CancelProductionOrdersFailed, CancelProductionOrderSkipped, CancelProductionOrdersSkipped, CancelProductionOrdersSuccess } from '../../api/cancel'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkCancelForm from '../forms/BulkCancelForm'
import BulkProgress from './BulkProgress'
import ExportCSV from '../generic/ExportCSV'
import moment from 'moment'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
}

class BulkCancelPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkCancel = this.sendBulkCancel.bind(this)
  }

  sendBulkCancel(x: BulkCancelBase) {
    const productionOrderIdList = x.productionOrderIds
      .replaceAll('\r\n', ',')
      .replaceAll('\n', ',')
      .replaceAll('\r', ',')
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkCancelRequest({
        productionOrderIds: productionOrderIdList
      })
    )
  }

  renderForm() {
    return <BulkCancelForm input=
      {{
        productionOrderIds: ''
      }}
      onSubmit={this.sendBulkCancel}></BulkCancelForm>
  }

  toCsvData(data: BulkCancelResponse) {
    return [
      ["orderCode", "productionOrderIds", "errorMessage", "status"],
      ...data.productionOrdersNotFound!.map((item: string) => [null, item, "Not Found", "Not Found"]),
      ...data.productionOrdersCancelFailed!.flatMap((item: CancelProductionOrdersFailed) => item.cancelProductionOrderFailedList.map((c: CancelProductionOrderFailed) => [item.orderCode, c.productionOrderId, c.errorMessage, "Not Cancelled"])),
      ...data.productionOrdersCancelSuccess!.map((item: CancelProductionOrdersSuccess) => [item.orderCode, item.productionOrderIds, "", "Success"]),
      ...data.productionOrdersCancelSkipped!.flatMap((item: CancelProductionOrdersSkipped) => item.cancelProductionOrderSkippedList.map((c: CancelProductionOrderSkipped) => [item.orderCode, c.productionOrderId, c.reason, "Skipped"])),
    ];
  }

  renderResponse() {
    console.log('Bulk Cancel Response:', this.props.bulkCancelStatusResponse);
    var downloadFileName = `productionOrderCancel-${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Cancel Result</h1>
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        <div className="response-container">
          {this.props.bulkCancelStatusResponse.result!.productionOrdersCancelSuccess && this.props.bulkCancelStatusResponse.result!.productionOrdersCancelSuccess.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-success status-heading'>
                  Cancel Successfully
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="10" className="column-highlight">
                  Production Order Ids
                </Col>
              </Row>
              {this.props.bulkCancelStatusResponse.result!.productionOrdersCancelSuccess!.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="success-column">
                    {x.orderCode}
                  </Col>
                  <Col md="10" className="success-column">
                    {x.productionOrderIds?.join(', ')}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkCancelStatusResponse.result!.productionOrdersCancelFailed && this.props.bulkCancelStatusResponse.result!.productionOrdersCancelFailed.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Cancel Failed
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="5" className="column-highlight">
                  Production Order Ids
                </Col>
                <Col md="5" className="column-highlight">
                  Error Messages
                </Col>
              </Row>
              {this.props.bulkCancelStatusResponse.result!.productionOrdersCancelFailed!.map((cancelProductionOrdersFailed, cancelProductionOrdersFailedIndex) => (
                <Row key={cancelProductionOrdersFailedIndex}>
                  <Col md="12">
                    <Row className='response-row'>
                      <Col md="2" className="failed-column">
                        {cancelProductionOrdersFailed.orderCode}
                      </Col>
                      <Col md="10">
                        {cancelProductionOrdersFailed.cancelProductionOrderFailedList.map((cancelProductionOrderFailed, cancelProductionOrderFailedIndex) => (
                          <>
                            <Row key={cancelProductionOrderFailedIndex}>
                              <Col md="6" className="failed-column">
                                {cancelProductionOrderFailed.productionOrderId}
                              </Col>
                              <Col md="6" className="failed-column">
                                {cancelProductionOrderFailed.errorMessage}
                              </Col>
                            </Row>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkCancelStatusResponse.result!.productionOrdersNotFound && this.props.bulkCancelStatusResponse.result!.productionOrdersNotFound.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Production Orders Not Found
                </Col>
              </Row>
              <Row>
                <Col md="12" className="column-highlight">
                  Production Orders
                </Col>
              </Row>
              {this.props.bulkCancelStatusResponse.result!.productionOrdersNotFound!.map((x, index) => (
                <Row key={index}>
                  <Col md="12" className="notfound-column">
                    {x}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkCancelStatusResponse.result!.productionOrdersCancelSkipped && this.props.bulkCancelStatusResponse.result!.productionOrdersCancelSkipped.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Non-Cancellable Production Orders
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="5" className="column-highlight">
                  Production Order Ids
                </Col>
                <Col md="5" className="column-highlight">
                  Reasons
                </Col>
              </Row>
              {this.props.bulkCancelStatusResponse!.result!.productionOrdersCancelSkipped!.map((cancelProductionOrdersSkipped, cancelProductionOrdersSkippedIndex) => (
                <Row key={cancelProductionOrdersSkippedIndex}>
                  <Col md="12">
                    <Row className='response-row'>
                      <Col md="2" className="skipped-column">
                        {cancelProductionOrdersSkipped.orderCode}
                      </Col>
                      <Col md="10">
                        {cancelProductionOrdersSkipped.cancelProductionOrderSkippedList.map((cancelProductionOrderSkipped, cancelProductionOrderSkippedIndex) => (
                          <>
                            <Row key={cancelProductionOrderSkippedIndex}>
                              <Col md="6" className="skipped-column">
                                {cancelProductionOrderSkipped.productionOrderId}
                              </Col>
                              <Col md="6" className="skipped-column">
                                {cancelProductionOrderSkipped.reason}
                              </Col>
                            </Row>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </>
          )}
          <Row>
            <Col md="12" className="export-csv">
              <ExportCSV data={this.toCsvData(this.props.bulkCancelStatusResponse.result!)} fileName={downloadFileName} />
            </Col>
          </Row>
        </div>
      </Container>
    )
  }

  renderProgress() {
    return (
      <BulkProgress itemsProcessed={this.props.bulkCancelStatusResponse.itemsProcessed} totalItems={this.props.bulkCancelStatusResponse.totalItems} remaining={this.props.bulkCancelStatusResponse.remaining} />
    )
  }

  render() {
    if (this.props.bulkCancelStatusResponse.inprogress) {
      return this.renderProgress();
    }
    return this.props.bulkCancelStatusResponse.valid ? this.renderResponse() : this.renderForm()
  }
}

export default BulkCancelPanel
